import React, {createContext, useContext, type PropsWithChildren} from "react";

import {type RenderMode} from ".";
import {type RenderModeService} from "./v1";

const RenderModeContext = createContext<RenderMode | undefined>(undefined);

export interface RenderModeContextProviderProps {
  readonly renderModeService: RenderModeService;
}
const RenderModeContextProvider = ({
  children,
  renderModeService,
}: PropsWithChildren<RenderModeContextProviderProps>): JSX.Element => {
  const renderMode = renderModeService.getRenderMode();

  return (
    <RenderModeContext.Provider value={renderMode}>
      {children}
    </RenderModeContext.Provider>
  );
};
RenderModeContextProvider.displayName = "RenderModeContextProvider";

export {RenderModeContextProvider};

export function useRenderMode(): RenderMode {
  const renderMode = useContext(RenderModeContext);
  if (renderMode === undefined) {
    throw new Error(
      `The required "renderModeService" couldn't be found. Did you forget to wrap your component with <RenderModeContextProvider />?
<RenderModeContextProvider renderModeService={renderModeService}>
  <YOUR_COMPONENT />
</RenderModeContextProvider>`
    );
  }
  return renderMode;
}
