import { Asset } from '@oneaudi/falcon-tools';
import { RenderMode } from '@oneaudi/render-mode-service';
import {
  Content,
  ContentVariant,
  HeadlessContent,
  HeadlessContentVariant,
} from '../EditorContentTypes';

export const mapHeadlessContent = (
  content: Content | HeadlessContent,
  renderMode: RenderMode,
): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalisedContent: Content = {
      defaultContent: {
        individualId: content.fields.defaultContent_individualId,
        text: content.fields.defaultContent_text,
        image: {
          xs: {
            assetValue: providePlaceHolder(
              (content.fields.defaultContent_image_xs as Asset).path,
              'https://dummyimage.com/375x390',
              renderMode,
            ),
          },
          m: {
            assetValue: providePlaceHolder(
              (content.fields.defaultContent_image_m as Asset).path,
              'https://dummyimage.com/375x390',
              renderMode,
            ),
          },
          xxl: {
            assetValue: providePlaceHolder(
              (content.fields.defaultContent_image_xxl as Asset).path,
              'https://dummyimage.com/375x390',
              renderMode,
            ),
          },
          altText: content.fields.defaultContent_image_altText,
        },
        legalData: {
          additionalText: content.fields.defaultContent_legalData_additionalText,
          wltpKeys: content.fields.defaultContent_legalData_wltpKeys,
        },
      },
      variants: mapContentVariant(content.fields.variants, renderMode),
      buttons: {
        registerButtonUrl: content.fields.buttons_registerButtonUrl,
        learnMoreButtonUrl: content.fields.buttons_learnMoreButtonUrl,
      },
    };
    return normalisedContent;
  }
  return content as Content;
};
function mapContentVariant(
  variants: HeadlessContentVariant[],
  renderMode: RenderMode,
): ContentVariant[] {
  const contentVariants: ContentVariant[] = [];

  variants.forEach((variant) => {
    contentVariants.push({
      individualId: providePlaceHolder(
        variant.fields.individualId,
        'individualId placeholder',
        renderMode,
      ),
      text: providePlaceHolder(variant.fields.text, 'text placeholder', renderMode),
      image: {
        xs: {
          assetValue: providePlaceHolder(
            (variant.fields.image_xs as Asset).path,
            'https://dummyimage.com/375x390',
            renderMode,
          ),
        },
        m: {
          assetValue: providePlaceHolder(
            (variant.fields.image_m as Asset).path,
            'https://dummyimage.com/375x390',
            renderMode,
          ),
        },
        xxl: {
          assetValue: providePlaceHolder(
            (variant.fields.image_xxl as Asset).path,
            'https://dummyimage.com/1920x1280',
            renderMode,
          ),
        },
        altText: variant.fields.image_altText,
      },
      legalData: {
        additionalText: variant.fields.legalData_additionalText,
        wltpKeys: variant.fields.legalData_wltpKeys,
      },
    });
  });
  return contentVariants;
}

/**
 * If a field is has no content but we are in a the RenderMode.EDIT
 * Then we set a placeholder text to make the editing in the UE possible.
 * Otherwise the element may not be shown, which makes it impossible to edit it in the
 * UE
 *
 * @param field
 * @param placeholder
 * @param renderMode
 * @returns
 */
function providePlaceHolder(field: string, placeholder: string, renderMode: RenderMode) {
  if (!field && renderMode === RenderMode.EDIT) {
    return placeholder;
  }

  return field;
}
