import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider, audiDarkTheme } from '@audi/audi-ui-react';
import {
  getConsumptionsAndEmissions,
  transformToTrackingText,
  useContent,
} from '@oneaudi/feature-app-utils';
import { LocaleServiceConfig } from '@volkswagen-onehub/locale-service';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';

import { MyAudiBenefitCommunicationTeaser } from './components';
import { MyAudiBenefitCommunicationTeaserProps } from './components/CompontentTypes';
import { Context } from './context';
import { I18nContextProvider } from './i18n/i18nContext';
import { useTrackingManager } from './context/useTrackingManager';
import { useInViewObserver } from './context/useInViewObserver';
import { randomContentVariant } from './utils/randomContent';
import { mapToTeaserProps } from './utils/mapToTeaserProps';

import { ContentVariant, Content, HeadlessContent } from './EditorContentTypes';
import { useI18n } from './i18n';
import { usePreviewVariant, PreviewVariantMatchingMessage } from './context/usePreviewVariant';
import { AsyncStateHolder, State } from './FeatureAppTypes';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

const MyAudiTeaserFeatureApp: React.FunctionComponent = () => {
  const previewVariant = usePreviewVariant();
  const [previewVariantError, setPreviewVariantError] = useState(false);
  const { vueFormatterService, localeService, logger, contentService, renderModeService } =
    useContext(Context);

  const inView = useInViewObserver();

  const trackingManager = useTrackingManager();

  const myAudiText = 'myAudi';

  const i18nTexts = {
    registerButtonText: useI18n('registerButtonText'),
    learnMoreButtonText: useI18n('learnMoreButtonText'),
  };
  const renderMode = renderModeService?.getRenderMode?.() ?? 0;
  const initialContent = useContent<Content | HeadlessContent>();
  if (!initialContent) return null;
  const content = mapHeadlessContent(initialContent, renderModeService?.getRenderMode?.() || 0);

  const [selectedContentVariant, setSelectedContentVariant] = useState<ContentVariant | undefined>(
    undefined,
  );

  const [footnoteRegisteredContent, setFootnoteRegisteredContent] =
    useState<MyAudiBenefitCommunicationTeaserProps>();

  useEffect(() => {
    trackingManager.ready(__FEATURE_APP_VERSION__);
  }, []);

  const deepEqual = (obj1: ContentVariant, obj2: ContentVariant) =>
    JSON.stringify(obj1) === JSON.stringify(obj2);

  const memoizedSelectedContentVariant = useMemo<ContentVariant | undefined>(() => {
    if (!content) return undefined;
    const selectContentByIndividualId = (individualId: string): ContentVariant | undefined =>
      [content.defaultContent, ...content.variants].find(
        (variant) => variant.individualId === individualId,
      ) || undefined;

    let variant;
    if (previewVariant) {
      variant = selectContentByIndividualId(previewVariant);
      return variant || undefined;
    }

    if (selectedContentVariant) {
      variant = selectContentByIndividualId(selectedContentVariant.individualId);
      if (variant && !deepEqual(variant, selectedContentVariant)) {
        return variant || randomContentVariant(content);
      }
      return selectedContentVariant;
    }

    return randomContentVariant(content);
  }, [content, previewVariant, selectedContentVariant?.individualId]);

  const memoizedWltpKeys = useMemo(
    () => selectedContentVariant?.legalData.wltpKeys,
    [selectedContentVariant?.legalData.wltpKeys],
  );

  useEffect(() => {
    if (previewVariant && !memoizedSelectedContentVariant) {
      setPreviewVariantError(true);
    } else {
      setPreviewVariantError(false);
    }
  }, [memoizedSelectedContentVariant, previewVariant]);

  useEffect(() => {
    setSelectedContentVariant(memoizedSelectedContentVariant);
  }, [memoizedSelectedContentVariant]);

  useEffect(() => {
    let isMounted = true;
    const fetchAndSetContent = async () => {
      if (content && selectedContentVariant?.legalData.wltpKeys) {
        try {
          const consumptionsAndEmissions = await getConsumptionsAndEmissions(
            selectedContentVariant.legalData.wltpKeys,
            vueFormatterService,
            localeService as LocaleServiceConfig,
            logger,
          );

          if (isMounted) {
            const mappingProps = mapToTeaserProps(
              selectedContentVariant,
              content.buttons,
              i18nTexts,
              myAudiText,
              consumptionsAndEmissions,
            );

            setFootnoteRegisteredContent((prevContent) => {
              if (JSON.stringify(prevContent) !== JSON.stringify(mappingProps)) {
                return mappingProps;
              }
              return prevContent;
            });
          }
        } catch (error) {
          logger?.error('Error fetching consumptions and emissions', error);
        }
      } else {
        setFootnoteRegisteredContent(undefined);
      }
    };

    fetchAndSetContent();

    return () => {
      isMounted = false;
    };
  }, [memoizedWltpKeys]);

  useEffect(() => {
    if (inView && selectedContentVariant) {
      trackingManager.impression(
        selectedContentVariant.individualId,
        transformToTrackingText(`${selectedContentVariant.text} ${myAudiText}`),
      );
    }
  }, [inView, selectedContentVariant]);

  const linkTracking = useCallback(
    (href: string, text: string, clickId: string, eventAction?: string): void => {
      trackingManager.click(
        href,
        text,
        clickId,
        transformToTrackingText(`${selectedContentVariant?.text} ${myAudiText}`),
        eventAction,
      );
    },
    [selectedContentVariant],
  );

  return renderModeService && renderMode === 1 ? (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <ThemeProvider theme={audiDarkTheme}>
        {previewVariant && <PreviewVariantMatchingMessage error={previewVariantError} />}
        {footnoteRegisteredContent && (
          <MyAudiBenefitCommunicationTeaser
            {...footnoteRegisteredContent}
            linkTracking={linkTracking}
          />
        )}
      </ThemeProvider>
    </UniversalEditorProvider>
  ) : (
    <ThemeProvider theme={audiDarkTheme}>
      {previewVariant && <PreviewVariantMatchingMessage error={previewVariantError} />}
      {footnoteRegisteredContent && (
        <MyAudiBenefitCommunicationTeaser
          {...footnoteRegisteredContent}
          linkTracking={linkTracking}
        />
      )}
    </ThemeProvider>
  );
};

MyAudiTeaserFeatureApp.displayName = 'MyAudiTeaserFeatureApp';

interface Props {
  readonly asyncStateHolder: AsyncStateHolder;
}

const AsyncFeatureApp: React.FunctionComponent<Props> = ({ asyncStateHolder }: Props) => {
  const { authServiceProvider, featureAppGroup, logger } = useContext(Context);

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  // when asyncStateHolder is an object it represents the serialized state coming
  // from the server ready to be used as the initial state
  const [state, setState] = useState<State | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );

  React.useEffect(() => {
    let mounted = true;
    const authService = authServiceProvider.register(featureAppGroup);
    authService.isAuthenticated().then((authenticated) => {
      if (mounted && !authenticated) {
        setIsAuthenticated(authenticated);
      }
    });

    // when asyncStateHolder is a function it means the state could
    // not be properly serialized by the server and it is not available on the client.
    // In that case this effect will try to fetch the state as soon as the component
    // is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then((asyncState) => {
        if (mounted) {
          setState(asyncState);
        }
      });
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }

    return () => {
      mounted = false;
    };
  }, []);

  if (!state || isAuthenticated) {
    return null;
  }

  return (
    <I18nContextProvider i18nMessages={state.i18nMessages}>
      <MyAudiTeaserFeatureApp />
    </I18nContextProvider>
  );
};

export default AsyncFeatureApp;
