import { useContext, useState, useEffect } from 'react';
import { Context } from '.';

require('intersection-observer');

export const useInViewObserver = (): boolean => {
  const ref = useContext(Context).inViewRef;
  const [isTriggered, setIsTriggered] = useState(false);
  const delay = 1000;
  let timeoutid70: number;
  let timeoutid100: number;

  const visibilityCallback70 = (
    entries: IntersectionObserverEntry[],
    observerInstance: IntersectionObserver,
  ) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      // only set trigger if el is still in viewport after 1 second
      if (entry.intersectionRatio > 0) {
        timeoutid70 = window.setTimeout(triggerCallback, delay);
      } else {
        window.clearTimeout(timeoutid70);
      }

      if (isTriggered) {
        observerInstance.disconnect();
      }
    });
  };

  const visibilityCallback100 = (
    entries: IntersectionObserverEntry[],
    observerInstance: IntersectionObserver,
  ) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      // only set trigger if el is still in viewport after 1 second
      if (entry.intersectionRatio === 1) {
        timeoutid100 = window.setTimeout(triggerCallback, delay);
      } else {
        window.clearTimeout(timeoutid100);
      }

      if (isTriggered) {
        observerInstance.disconnect();
      }
    });
  };

  const triggerCallback = () => {
    setIsTriggered(true);
  };

  useEffect(() => {
    // observer for complete visibility
    const observer100 = new IntersectionObserver(visibilityCallback100, {
      threshold: 1,
    });

    // observer for partial visibility (element intersects upper 70% of viewport)
    const observer70 = new IntersectionObserver(visibilityCallback70, {
      rootMargin: '0% 0% -30% 0%',
      threshold: 0,
    });

    if (ref.current && !isTriggered) {
      observer100.observe(ref.current);
      observer70.observe(ref.current);
    }
  }, []);

  return isTriggered;
};
