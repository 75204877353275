import { ReactNode } from 'react';
import { ConsumptionsAndEmissions } from '@oneaudi/vue-formatter-service';

import {
  getImageUrls,
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
} from '@oneaudi/feature-app-utils';
import {
  MyAudiBenefitCommunicationTeaserProps,
  WltpProps,
  ImageProps as ComponentImageProps,
} from '../components/CompontentTypes';
import { ButtonUrls, ContentVariant, I18nTexts, ImageProps } from '../EditorContentTypes';

const mapImages = (image: ImageProps): ComponentImageProps => {
  return {
    xs: getImageUrls(image.xs.assetValue)[0],
    m: getImageUrls(image.m.assetValue)[0],
    xxl: getImageUrls(image.xxl.assetValue)[0],
    altText: image.altText,
  };
};

export const mapToTeaserProps = (
  { individualId, image, text, legalData }: ContentVariant,
  buttonUrls: ButtonUrls,
  i18nTexts: I18nTexts,
  myAudiText: string,
  consumptionsAndEmissions: ConsumptionsAndEmissions[],
): MyAudiBenefitCommunicationTeaserProps => {
  let additionalText: ReactNode[] | undefined;
  if (legalData.additionalText) {
    additionalText = renderTextWithFootnotesReferencesV2(legalData.additionalText);
  }

  const wltpData: WltpProps[] = [];
  consumptionsAndEmissions.forEach(
    ({
      formattedConsumption,
      formattedEmission,
      formattedCo2Class,
      formattedDischargedConsumption,
      formattedDischargedCo2Class,
    }) => {
      if (formattedConsumption && formattedEmission) {
        wltpData.push({
          formattedConsumption: renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedConsumption),
          ),
          formattedEmission: renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(formattedEmission),
          ),
          formattedCo2Class: formattedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedCo2Class),
              )
            : undefined,
          formattedDischargedConsumption: formattedDischargedConsumption
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedDischargedConsumption),
              )
            : undefined,
          formattedDischargedCo2Class: formattedDischargedCo2Class
            ? renderTextWithFootnotesReferencesV2(
                cleanupEmissionConsumptionMarkup(formattedDischargedCo2Class),
              )
            : undefined,
        });
      }
    },
  );

  return {
    individualId,
    image: mapImages(image),
    text: renderTextWithFootnotesReferencesV2(text),
    myAudiText,
    registerButton: {
      href: buttonUrls.registerButtonUrl,
      text: i18nTexts.registerButtonText,
    },
    learnMoreButton: {
      href: buttonUrls.learnMoreButtonUrl,
      text: i18nTexts.learnMoreButtonText,
    },
    legalData: {
      additionalText,
      wltpData,
    },
  };
};
