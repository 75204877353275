import { Content, ContentVariant } from '../EditorContentTypes';

/**
 * @param {number} max - The upper limit of the random number
 * @returns {number} A random number between 0 and max
 */
const randomNumber = (max: number) => Math.floor(Math.random() * (max + 1));

/**
 * /**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-myaudi-benefit-teaser
 * Internal](https://github.com/oneaudi/fa-myaudi-benefit-teaser). Update all of your dependencies now!
 * @param {Content} content - The content object from which to chose a random ContentVariant
 * @returns {ContentVariant} A random ContentVariant from the given Content (including defaultContent)
 */
export const randomContentVariant = (content: Content): ContentVariant => {
  const { variants } = content;
  if (variants.length > 0) {
    const random = randomNumber(variants.length);
    /*
     * If the random number is between 0 and last index return the variant of the given random number, 
    // otherwise return defaultContent.
     */
    if (random < variants.length) {
      return variants[random];
    }
  }
  /*
   * return defaultContent if no variants are given or none of the variants was chosen by the random number algorythm.
   */
  return content.defaultContent;
};
