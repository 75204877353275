import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';

interface PreviewVariantContextState {
  // eslint-disable-next-line react/require-default-props
  readonly previewVariant?: string;
}

const PreviewVariantContext = createContext<PreviewVariantContextState>({});

export const PreviewVariantContextProvider = ({
  children,
  previewVariant,
}: PropsWithChildren<PreviewVariantContextState>): JSX.Element => {
  return (
    <PreviewVariantContext.Provider
      value={useMemo(
        () => ({
          previewVariant,
        }),
        [previewVariant],
      )}
    >
      {children}
    </PreviewVariantContext.Provider>
  );
};

export const usePreviewVariant = (): string | undefined =>
  useContext(PreviewVariantContext).previewVariant;

const Message = styled.span`
  color: var(${({ theme }) => theme.colors.ui.error});
  display: block;
`;

export interface PreviewVariantMatchingMessageProps {
  readonly error: boolean;
}

export const PreviewVariantMatchingMessage = ({
  error,
}: PreviewVariantMatchingMessageProps): JSX.Element => {
  const previewVariant = usePreviewVariant();
  const resolveHint =
    "WARNING: Don't forget to empty the JSON Configuration before publishing the teaser.";
  const message = error
    ? `No matching Tracking ID found for the configured previewVariant "${previewVariant}". Please check the previewVariant configuration again.`
    : `This feature app is configured with previewVariant "${previewVariant}".`;

  return (
    <Text as="h1" variant="order3">
      <Message>{message}</Message>
      <Message>{resolveHint}</Message>
    </Text>
  );
};
