import {type RenderMode} from "./";

export interface RenderModeService {
  getRenderMode: () => RenderMode;
}

export class RenderModeServiceV1 implements RenderModeService {
  public constructor(private readonly renderMode: RenderMode) {}

  getRenderMode(): RenderMode {
    return this.renderMode;
  }
}
