import React from 'react';
import styled from 'styled-components';
import { ButtonGroup, Button, Text } from '@audi/audi-ui-react';
import { addWidthParameter } from '@oneaudi/feature-app-utils';
import { UeElement } from '@oneaudi/falcon-tools';
import { ImageWidth, toSrcSet } from './utils/url-utils';

import { MyAudiBenefitCommunicationTeaserProps, WltpProps } from './CompontentTypes';

const GRID_COLUMNS = 25;
const GRID_WIDTH_PERCENTAGE = 100 / GRID_COLUMNS;
type GridImageColumns = 10 | 11 | 12;
const toPercentage = (columns: GridImageColumns) => `${GRID_WIDTH_PERCENTAGE * columns}%`;

const Container = styled.div`
  background: var(${(props) => props.theme.colors.ui.inverted});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: flex;
    flex-direction: row-reverse;
  }
`;
const ImageBox = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    flex: 0 0 ${toPercentage(10)};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    flex: 0 0 ${toPercentage(11)};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    flex: 0 0 ${toPercentage(12)};
  }
`;
const StyledImg = styled.img`
  width: 100%;
  height: 370px;
  object-fit: cover;
  display: block;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    height: auto;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 590px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    height: 768px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    height: 948px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    height: 1080px;
  }
`;

const StyledBlockSpan = styled.span`
  display: block;
`;

StyledBlockSpan.displayName = 'StyledBlockSpan';

const TextBox = styled.div`
  padding: var(${(props) => props.theme.responsive.spacing.pageMargin});
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
`;

const WltpSpan = styled.span``;

WltpSpan.displayName = 'WltpSpan';

const WltpValuesComponent: React.FC<WltpProps> = ({
  formattedConsumption,
  formattedEmission,
  formattedCo2Class,
  formattedDischargedCo2Class,
  formattedDischargedConsumption,
}: WltpProps) => {
  return (
    <StyledBlockSpan>
      <WltpSpan data-testid="consumption">{formattedConsumption}</WltpSpan>
      <WltpSpan data-testid="emission">; {formattedEmission}</WltpSpan>
      {formattedCo2Class && <WltpSpan data-testid="co2Class">; {formattedCo2Class}</WltpSpan>}
      {formattedDischargedConsumption && (
        <WltpSpan data-testid="dischargedConsumption">; {formattedDischargedConsumption}</WltpSpan>
      )}
      {formattedDischargedCo2Class && (
        <WltpSpan data-testid="dischargedCo2Class">; {formattedDischargedCo2Class}</WltpSpan>
      )}
    </StyledBlockSpan>
  );
};

export const MyAudiBenefitCommunicationTeaser: React.FunctionComponent<
  MyAudiBenefitCommunicationTeaserProps
> = ({
  individualId,
  image,
  text,
  myAudiText,
  registerButton,
  learnMoreButton,
  linkTracking,
  legalData,
}: MyAudiBenefitCommunicationTeaserProps) => (
  <Container data-testid="benefitTeaser">
    <ImageBox>
      <UeElement type="media" property="image" label="Image">
        {(ueProps) => (
          <picture>
            <source
              media="(min-width:1920px)"
              srcSet={toSrcSet(image.xxl, ImageWidth.XXL)}
              data-testid="imageXXL"
            />
            <source
              media="(min-width:768px)"
              srcSet={toSrcSet(image.m, ImageWidth.M)}
              data-testid="imageM"
            />
            <StyledImg
              srcSet={toSrcSet(image.xs, ImageWidth.XS)}
              src={addWidthParameter(image.xs, ImageWidth.XS)}
              alt={image.altText}
              data-testid="image"
              {...ueProps}
            />
          </picture>
        )}
      </UeElement>
    </ImageBox>
    <TextBox>
      <UeElement type="text" property="text" label="Text">
        <Text as="h3" variant="order2">
          {text}
          <br />
          <UeElement type="text" property="myAudiText" label="myAudiText">
            <Text as="strong" weight="bold" variant="order2">
              {myAudiText}
            </Text>{' '}
          </UeElement>
        </Text>
      </UeElement>
      <ButtonGroup variant="block-buttons" spaceStackStart="xl">
        <Button
          variant="secondary"
          href={registerButton.href}
          data-testid="register"
          // "register" button should always be an exit link
          onClick={() =>
            linkTracking &&
            linkTracking(registerButton.href, registerButton.text, individualId, 'exit_link')
          }
        >
          <UeElement type="text" property="text" label="Register Button Text">
            {registerButton.text}
          </UeElement>
        </Button>
        <Button
          variant="secondary"
          href={learnMoreButton.href}
          data-testid="learn-more"
          onClick={() =>
            linkTracking && linkTracking(learnMoreButton.href, learnMoreButton.text, individualId)
          }
        >
          <UeElement type="text" property="text" label="Learn More Button Text">
            {learnMoreButton.text}
          </UeElement>
        </Button>
      </ButtonGroup>
      {(legalData.wltpData.length > 0 || legalData.additionalText) && (
        <UeElement type="text" property="text" label="Legal Text">
          <Text variant="copy2" spaceStackStart="xxl" data-testid="legal">
            {legalData.wltpData &&
              // eslint-disable-next-line react/no-array-index-key
              legalData.wltpData.map((data, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <WltpValuesComponent {...data} key={index} />
              ))}
            {legalData.additionalText && (
              <UeElement type="text" property="text" label="Additional text">
                <WltpSpan data-testid="additional-legal-text">{legalData.additionalText}</WltpSpan>
              </UeElement>
            )}
          </Text>
        </UeElement>
      )}
    </TextBox>
  </Container>
);
