import { addWidthParameter } from '@oneaudi/feature-app-utils';

export enum ImageWidth {
  XS = 767,
  M = 845,
  XXL = 922,
}

export const toSrcSet = (imageUrl: string, imageWidth: ImageWidth): string => {
  try {
    return `${addWidthParameter(imageUrl, imageWidth)} 1x, ${imageUrl} 2x`;
  } catch (e) {
    return imageUrl;
  }
};
