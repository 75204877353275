import {
  type FeatureServiceBinder,
  type FeatureServiceProviderDefinition,
  type SharedFeatureService,
} from "@feature-hub/core";

import {type RenderModeService, RenderModeServiceV1} from "./v1";

export * from "./context";
export * from "./v1";

export enum RenderMode {
  "DEFAULT",
  "EDIT",
}

export interface SharedRenderModeService extends SharedFeatureService {
  "1.0.0": FeatureServiceBinder<RenderModeService>;
}

export function defineRenderModeService(
  renderMode: RenderMode
): FeatureServiceProviderDefinition<SharedRenderModeService> {
  return {
    id: "audi-render-mode-service",

    create: () => {
      return {
        "1.0.0": () => {
          return {featureService: new RenderModeServiceV1(renderMode)};
        },
      };
    },
  };
}
