/* eslint-disable react/display-name */
import React from 'react';
import { ReactFeatureApp } from '@feature-hub/react';
import { FeatureAppDefinition } from '@feature-hub/core';

import { ContentContextProvider, FootnoteContextProvider } from '@oneaudi/feature-app-utils';

import { PreviewVariantContextProvider } from './context/usePreviewVariant';
import { ContextProvider } from './context';
import { fetchI18nMessages } from './i18n';
import AsyncFeatureApp from './FeatureApp';
import { AsyncStateHolder, Dependencies, State } from './FeatureAppTypes';

export interface Config {
  readonly featureAppGroup?: string;
  readonly previewVariant?: string;
}

const MyAudiTeaserFeatureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  Dependencies,
  Config
> = {
  dependencies: {
    externals: {
      '@audi/audi-ui-react-v2': '^3.4.0',
      '@feature-hub/react': '^3.5.0',
      react: '^17.0.2 || 18.2.0',
      'react-dom': '^17.0.2 || 18.2.0',
      'styled-components': '^5.3.0',
    },
    featureServices: {
      'audi-content-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-tracking-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-footnote-reference-service': '3.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'gfa:auth-service': '^1.0.0',
    },
  },

  create: ({ config, featureServices }) => {
    const featureAppGroup = config?.featureAppGroup || 'myaudi';
    const {
      'audi-content-service': contentService,
      'audi-tracking-service': trackingService,
      'gfa:locale-service': localeService,
      's2:logger': logger,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'dbad:audi-i18n-service': i18nService,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
    } = featureServices;

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    if (trackingService) {
      // set feature app name as early as possible
      trackingService.featureAppName = 'audi-myaudi-benefit-communication-teaser';
    }

    async function createInitialState(): Promise<State> {
      const i18nMessages = await fetchI18nMessages(localeService, i18nService);
      return { i18nMessages };
    }

    let asyncStateHolder: AsyncStateHolder;
    if (asyncSsrManager) {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          asyncStateHolder = await createInitialState();
          serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
        })(),
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();
      if (serializedState) {
        asyncStateHolder = JSON.parse(serializedState) as State;
        /** TODO
         * Remove once SSR on author preview mode was fixed by BaD. THIS IS JUST A TEMPORARY
         * WORKAROUND AND NOT A PERMANENT SOLUTION
         */
        if (
          !asyncStateHolder?.i18nMessages?.learnMoreButtonText ||
          !asyncStateHolder?.i18nMessages?.registerButtonText
        ) {
          logger?.warn(
            `Serialized state was found, but didn't contain the expected i18n texts!. Reason: SSR doesn't resolve i18n correctly on author in preview mode. As a workaround the state will be reinitialized.`,
          );
          asyncStateHolder = () => createInitialState();
        }
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
        );
        asyncStateHolder = () => createInitialState();
      }
    }

    return {
      render: () => (
        <ContentContextProvider contentService={contentService}>
          <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
            <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
              <ContextProvider featureServices={featureServices} featureAppGroup={featureAppGroup}>
                <PreviewVariantContextProvider previewVariant={config?.previewVariant}>
                  <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
                </PreviewVariantContextProvider>
              </ContextProvider>
            </div>
          </FootnoteContextProvider>
        </ContentContextProvider>
      ),
    };
  },
};

export default MyAudiTeaserFeatureAppDefinition;
